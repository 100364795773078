"use strict";

$(document).ready(function () {
  /* Components */
  $(function () {
    $('.swiper-container-intro').each(function () {
      new Swiper('.swiper-container-intro', {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 30,
        effect: 'fade',
        pagination: {
          el: '.swiper-pagination'
        },
        autoplay: {
          delay: 3500
        }
      });
    });
    $('.swiper-container-reviews').each(function () {
      new Swiper('.swiper-container-reviews', {
        slidesPerView: 'auto',
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev'
        }
      });
    });
    var buttons = $('.js-open-modal-btn');
    buttons.each(function () {
      $(this).click(function () {
        var modalId = $(this).attr('data-modal-id');
        var modal = $('#' + modalId + '');
        $('body').addClass('overflow-hidden');
        modal.css("display", "block");
        modal.animate({
          opacity: 1,
          top: "0%"
        }, 500);
      });
    });
    var buttonsCloseAll = $('.js-close-modal');
    buttonsCloseAll.each(function () {
      $(this).click(function () {
        var allModals = $('.js-modal');
        allModals.animate({
          opacity: 0,
          top: "-5%"
        }, 500);
        $('body').removeClass('overflow-hidden');
        setTimeout(function () {
          allModals.css({
            "display": "none"
          });
        }, 500);
      });
    }); // Smooth scroll

    $('.js-scroll-to').on('click', function (event) {
      event.preventDefault();
      $('body,html').animate({
        scrollTop: 0
      }, 500);
    });
    $('.js-form').each(function () {
      var form = $(this);
      var buttonRequired = form.find('.js-btn-required');
      buttonRequired.each(function () {
        var clickedButton = $(this);
        clickedButton.on('click', function () {
          var hasError = false;
          var closestForm = $(this).closest(form); // Validation on empty fields

          closestForm.find('.js-required').each(function () {
            if ($(this).val().length === 0) {
              $(this).addClass('incorrect');
              hasError = true;
            }
          }); // If everything is fine

          if (!hasError) {
            var data = form.serialize();
            var url = form.attr('action');
            var method = form.attr('method');
            sendData(data, url, method);
          }
        });
      });
    }); // Remove incorrent class when user is typing

    $('.js-required').on('input keydown propertychange', function () {
      if ($(this).val().length > 0 && $(this).val() != $(this).attr('placeholder')) {
        $(this).removeClass('incorrect');
      }
    }); // Add the content after clicking on the button

    $('body').on('click', '.js-form-data-btn', function () {
      var btn = $(this);
      var data = {};
      var url = btn.attr('data-form-data-url');
      var method = btn.attr('data-form-data-method');
      var pageNumber = btn.attr('data-page-number');
      data.page = pageNumber;
      sendData(data, url, method, $(this));
    }); //Send data

    function sendData(data, url, method, button) {
      $.ajax({
        type: method,
        url: url,
        data: data,
        processData: true,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function success(data) {
          if (data.refresh) {
            window.location.reload();
          }

          if (data.appendContent) {
            if (data.appendContent.number) {
              var nextPageNumber = data.appendContent.number;
              button.attr('data-page-number', nextPageNumber);
            } else {
              button.css('display', 'none');
            }

            var name = data.appendContent.name;
            var content = data.appendContent.content;
            var block = $('.js-form-data-content[data-form-data-name="' + name + '"]');
            block.append(content);
          }
        },
        error: function error(data) {
          alert("Error!");
        }
      });
    }
  });
}); // Map

var mapElement = $('#map');
var markerImgUrl = mapElement.attr('data-map-marker-img');
var coordinateLat = Number(mapElement.attr('data-map-lat'));
var coordinateLng = Number(mapElement.attr('data-map-lng'));
console.log(coordinateLng);

function initMap() {
  var uluru = {
    lat: coordinateLat,
    lng: coordinateLng
  };
  var markerImage = markerImgUrl;
  var map = new google.maps.Map(document.getElementById('map'), {
    zoom: 18,
    center: uluru,
    mapTypeControl: false,
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    styles: [{
      "elementType": "geometry",
      "stylers": [{
        "color": "#f5f5f5"
      }]
    }, {
      "elementType": "labels.icon",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#616161"
      }]
    }, {
      "elementType": "labels.text.stroke",
      "stylers": [{
        "color": "#f5f5f5"
      }]
    }, {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#bdbdbd"
      }]
    }, {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [{
        "color": "#eeeeee"
      }]
    }, {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#757575"
      }]
    }, {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [{
        "color": "#e5e5e5"
      }]
    }, {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#9e9e9e"
      }]
    }, {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [{
        "color": "#ffffff"
      }]
    }, {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#757575"
      }]
    }, {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [{
        "color": "#dadada"
      }]
    }, {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#616161"
      }]
    }, {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#9e9e9e"
      }]
    }, {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [{
        "color": "#e5e5e5"
      }]
    }, {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [{
        "color": "#eeeeee"
      }]
    }, {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [{
        "color": "#c9c9c9"
      }]
    }, {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#9e9e9e"
      }]
    }]
  });
  var marker = new google.maps.Marker({
    position: uluru,
    map: map,
    icon: markerImage
  });
}